import React from 'react';


const SoenoughExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            
<p><strong>So, such, too, enough</strong> are intesifier words that strengthen the meaning of other expressions and show emphasis.</p>
<hr></hr>
                                        <p><strong>So + adjective, So + adverb</strong></p>
                                        <p>She's so talented. She plays the violin so well. </p>
                                        <p><strong>Such a + adjective + noun</strong></p>
                                        <p>This is such a beautiful garden.</p>
                                        <p><strong>Too + adjective, Too + adverb (ly)</strong></p>
                                        <p>The coffee was too hot. The car was going too quickly.</p>
                                        <p><strong>Enough + noun</strong></p>
                                        <p>I was finally able to get enough rest last night.</p>
                                        <p><strong>Adjective + enough</strong></p>
                                        <p>It was warm enough that we didn't need a jacket.</p>



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default SoenoughExplanation;