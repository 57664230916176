import React from 'react';


const SuggestExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            <h1>Suggest, Recommend, Offer, Promise</h1>

                            <p><strong>Suggest doing</strong> - I suggest taking a break to clear your mind.</p>  
<p><strong>Suggest someone do</strong> - I suggest you talk to your manager about the issue.</p>  
<p><strong>Suggest something</strong> - I suggest a different approach to solving this problem.</p>  
<hr></hr>  
<p><strong>Recommend doing</strong> - I recommend reading this book on leadership.</p>  
<p><strong>Recommend someone do</strong> - I recommend she apply for the promotion.</p>  
<p><strong>Recommend something</strong> - I recommend this restaurant for dinner.</p>  
<hr></hr>  
<p><strong>Offer to do</strong> - I offered to help him move his furniture.</p>  
<p><strong>Offer someone something</strong> - She offered me a cup of coffee.</p>  
<p><strong>Offer something</strong> - They offered a discount to first-time customers.</p>  
<hr></hr>  
<p><strong>Promise to do</strong> - He promised to finish the report by Friday.</p>  
<p><strong>Promise someone something</strong> - She promised him a raise if he met his targets.</p>  
<p><strong>Promise (that) someone will do</strong> - I promise that I’ll be on time for the meeting.</p>  



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default SuggestExplanation;







