import React from 'react';


const PassiveExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            <h1>Passive</h1>

<p><strong>Passive: "be" + past participle(3rd form: had, gone, done, given, etc,)</strong></p>
<hr></hr>
<p><strong>Present: am/is/are + 3rd form</strong></p>
<p><strong>Present continuous: am/is/are + being +  3rd form</strong></p>
<p><strong>Past: was/were + 3rd form</strong></p>
<p><strong>Present Perfect: have/has + been + 3rd form</strong></p>
<p><strong>The passive is most commonly used when talking about law/police, manufacturing (making products) and history.</strong></p>


                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default PassiveExplanation;







