import React from 'react';


const ReportedspeechExplanation = () => {

    return (
        <div>

<div className="grammar-display-grid">
                            <div className="agendagrammar">
                            <h2>Reported Speech (Statements)</h2>

<p>Reported speech is used to relay what someone said without quoting them directly. The tense usually shifts back (backshifting), depending on the context.</p>

<h3>Key Changes</h3>

<ul>
  <li><strong>Present simple → Past simple</strong>: "I work here." → He said he <em>worked</em> there.</li>
  <li><strong>Present continuous → Past continuous</strong>: "She is reading." → He said she <em>was reading</em>.</li>
  <li><strong>Present perfect → Past perfect</strong>: "I have finished." → He said he <em>had finished</em>.</li>
  <li><strong>Past simple → Past perfect</strong>: "They left early." → He said they <em>had left</em> early.</li>
  <li><strong>Will → Would</strong>: "She will help." → He said she <em>would</em> help.</li>
  <li><strong>Can → Could</strong>: "I can swim." → He said he <em>could</em> swim.</li>
  <li><strong>Must/Have to → Had to</strong>: "You must leave." → He said I <em>had to</em> leave.</li>
</ul>

<h3>Time & Place Changes</h3>

<ul>
  <li><strong>Now → Then</strong>: "I'm busy now." → He said he was busy <em>then</em>.</li>
  <li><strong>Today → That day</strong>: "I saw her today." → He said he had seen her <em>that day</em>.</li>
  <li><strong>Yesterday → The day before</strong>: "She called yesterday." → He said she had called <em>the day before</em>.</li>
  <li><strong>Tomorrow → The next day</strong>: "I'll go tomorrow." → He said he would go <em>the next day</em>.</li>
  <li><strong>Here → There</strong>: "I'm here." → He said he was <em>there</em>.</li>
</ul>

<h3>No Change in Tense</h3>
<p>If the reporting verb is in the present or if the original statement is about a general truth, no tense shift is needed:</p>
<ul>
  <li>"Water boils at 100°C." → He says that water <em>boils</em> at 100°C.</li>
  <li>"I love coffee." → She says she <em>loves</em> coffee.</li>
</ul>




                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default ReportedspeechExplanation;