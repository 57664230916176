import React from 'react';


const FirstconditionalExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            
                            <ul>
  <li><strong>If + will</strong></li>
</ul>


                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default FirstconditionalExplanation;