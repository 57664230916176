import React from 'react';


const MixedconditionalsExplanation = () => {

    return (
        <div>
            <div className="grammar-display-grid">
                <div className="agendagrammar">
                <h2>Mixed Conditionals</h2>

<p>Mixed conditionals are used when the two clauses of a conditional sentence refer to different times. They describe hypothetical situations with a past-present connection.</p>

<h3>Structure:</h3>
<p><strong>Past Condition → Present Result:</strong> If + past perfect, would + base verb</p>

<h3>Uses:</h3>
<ul>
  <li><strong>Past affecting present:</strong> "If I had studied harder, I would have a better job now."</li>
  <li><strong>Hypothetical scenarios:</strong> "If I had left earlier, I wouldn’t be stuck in traffic."</li>
</ul> 
                </div>                                    
            </div>
        </div>
);
};

export default MixedconditionalsExplanation;