import React from 'react';


const ThirdcondExplanation = () => {

    return (
        <div>

<div className="grammar-display-grid">
                            <div className="agendagrammar">
                        <p>If + had + past particple (3rd form), would have + verb</p>



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default ThirdcondExplanation;