import React from 'react';


const ModalsobligationExplanation = () => {

    return (
        <div>

<div className="grammar-display-grid">
                            <div className="agendagrammar">
                            <h2>Modals of Obligation, Permission, and Ability:</h2>

<h3>Can</h3>
<p><strong>Usage:</strong> Expresses ability, permission, or possibility.</p>
<ul>
  <li><strong>Ability:</strong> "I can fix bugs in JavaScript."</li>
  <li><strong>Permission:</strong> "You can take a break after completing this task."</li>
  <li><strong>Possibility:</strong> "It can be tricky to troubleshoot this error."</li>
</ul>

<h3>Can't</h3>
<p><strong>Usage:</strong> Expresses prohibition or inability.</p>
<ul>
  <li><strong>Prohibition:</strong> "You can't access this server without authorization."</li>
  <li><strong>Inability:</strong> "I can't solve this issue without the logs."</li>
</ul>

<h3>Could</h3>
<p><strong>Usage:</strong> Expresses past ability, polite requests, or suggestions.</p>
<ul>
  <li><strong>Past Ability:</strong> "I could write clean code when I was less busy."</li>
  <li><strong>Polite Request:</strong> "Could you help me with this bug?"</li>
  <li><strong>Suggestion:</strong> "We could use a different framework for this project."</li>
</ul>

<h3>May</h3>
<p><strong>Usage:</strong> Formal permission or possibility.</p>
<ul>
  <li><strong>Permission:</strong> "You may leave early if your tasks are done."</li>
  <li><strong>Possibility:</strong> "There may be issues with the latest update."</li>
</ul>

<h3>Might</h3>
<p><strong>Usage:</strong> Possibility, often weaker than "may."</p>
<ul>
  <li>"It might take a few hours to debug this."</li>
  <li>"The server might go down for maintenance."</li>
</ul>

<h3>Should / Ought to -- Shouldn’t</h3>
<p><strong>Usage:</strong> Obligation or advice.</p>
<ul>
  <li>"You should back up your data regularly."</li>
  <li>"We ought to test the application thoroughly."</li>
</ul>

<h3>Must / Have to -- Mustn’t / Don’t Have to</h3>
<p><strong>Usage:</strong> Strong obligation or necessity.</p>
<ul>
  <li>"You must update the system."</li>
  <li>"Employees have to follow company policies."</li>
</ul>

<h3>Need to -- Don’t Need To</h3>
<p><strong>Usage:</strong> Necessity.</p>
<ul>
  <li>"We need to fix this bug before release."</li>
  <li>"You need to reset your password every 3 months."</li>
</ul>


<h2>To be +</h2>

<h3>Mandatory</h3>
<p><strong>Meaning:</strong> Something that is demanded by law, rule, or authority, and there’s no choice about it.</p>
<p><strong>Context:</strong> Often used in formal or legal contexts.</p>
<p><strong>Example:</strong> "Attendance at the safety briefing is mandatory for all employees."</p>
<p><strong>Tone:</strong> Strong and authoritative.</p>

<h3>Obligatory</h3>
<p><strong>Meaning:</strong> Indicates that something is morally or legally necessary. It often has a formal tone, but less intense than "mandatory."</p>
<p><strong>Context:</strong> Used for duties or expectations rather than laws.</p>
<p><strong>Example:</strong> "It is obligatory to submit a report after every meeting."</p>
<p><strong>Tone:</strong> Formal, but less forceful than "mandatory."</p>

<h3>Required</h3>
<p><strong>Meaning:</strong> Something that is necessary or demanded in a specific situation. It can refer to formal rules or more informal expectations.</p>
<p><strong>Context:</strong> Used in many contexts, including legal, educational, and workplace situations.</p>
<p><strong>Example:</strong> "You are required to complete this task by the end of the day."</p>
<p><strong>Tone:</strong> Neutral, but still expresses necessity.</p>

<h3>Compulsory</h3>
<p><strong>Meaning:</strong> Indicates that something must be done because of a rule or law. It’s similar to "mandatory" but often used more in education or procedural contexts.</p>
<p><strong>Context:</strong> Commonly used for educational, administrative, or procedural rules.</p>
<p><strong>Example:</strong> "Attendance at this workshop is compulsory."</p>
<p><strong>Tone:</strong> Firm, slightly less formal than "mandatory."</p>

<h3>Necessary</h3>
<p><strong>Meaning:</strong> Refers to something that is needed or required for a specific purpose or result. It conveys that something is important but doesn't always imply strict rules or laws behind it. It often suggests that without this, something might not function properly or as expected.</p>
<p><strong>Context:</strong> Commonly used in both formal and informal settings to express basic requirements or conditions.</p>
<p><strong>Example:</strong> "It’s necessary to back up your data before updating the system."</p>
<p><strong>Tone:</strong> Neutral, emphasizing importance or practicality.</p>

<h3>Essential</h3>
<p><strong>Meaning:</strong> Describes something that is absolutely crucial or indispensable for a particular outcome or situation. It goes beyond "necessary" by implying that without this, success, safety, or functionality is impossible.</p>
<p><strong>Context:</strong> Often used in more serious or critical contexts, indicating something is of fundamental importance.</p>
<p><strong>Example:</strong> "It is essential that the server is secured to prevent data breaches."</p>
<p><strong>Tone:</strong> Stronger and more urgent than "necessary," often signaling vital importance.</p>




                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default ModalsobligationExplanation;