import React from 'react';


const SimplepastExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            
                            <ul>
  <li><strong>verb + ed or irregular</strong></li>
</ul>



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default SimplepastExplanation;