import React from 'react';


const PassivebeingExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            <h1>Passive - "Being"</h1>

<p><strong>Passive present gerunds: "being" + past participle</strong></p>

<p>The structure "being + 3rd form" turns a verb into a noun so you can use it with like, hate, type words. It means "the fact that I am verbed"</p>


  <p><strong>Being ignored</strong> doesn't feel good. (The fact that I am ignored doesn't feel good.)</p>
  <p>No one likes <strong>being lied to.</strong> (the situation when people lie to them)</p>
  <p>What the players love most is <strong>being given the freedom to practice how they want.</strong> (turns the whole phrase into a noun)</p>
  <p>I'm sick of <strong>being treated like a number.</strong> (the situation where people treat me like a number)</p>

<h1>Passive Past Gerunds - "Having Been"</h1>

<p>To make a passive past gerund, use <strong>having been</strong> plus a past participle verb. The <strong>having been</strong> part of the sentence clarifies that something happened before another past action. Let's take a look at five examples with the five functions we have already discussed.</p>
  
  <p><strong>They didn't like having been overlooked</strong> for the award. (gerund as object of a verb)</p>


<p>This structure is not very common, as most English speakers will usually opt to use the present form <strong>being</strong>, since the past is usually clear from context. For instance, it makes perfect sense to say, <em>"They didn't like being overlooked for the award"</em> instead of <em>"They didn't like having been overlooked for the award."</em> Both sentences are correct and can be understood as referring to the past based on context.</p>

<p>Also, there is another usage of <strong>having been</strong> to describe a previous event or situation and then talking about a result of this. This is usually used at the beginning of a sentence. Take a look:</p>

<p><em>Having been given the job, he decided to celebrate with his family.</em></p>

<p>This means "Now, after this event has happened and because of it,"</p>





                                        



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default PassivebeingExplanation;







