import React from 'react';


const FutureExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "left", border: "1px solid black" }}>
            <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <th style={{ border: "1px solid black", padding: "8px" }}>Type</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>Subject</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>Will</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>Base Verb</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>Object / Rest of Sentence</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>Positive</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>I / You / He / She / It / We / They</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>will</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>work</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>tomorrow.</td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>Negative</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>I / You / He / She / It / We / They</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>will not (won’t)</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>work</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>tomorrow.</td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>Yes/No Question</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>—</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>Will</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>I / you / he / she / it / we / they</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>work tomorrow?</td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>Wh- Question</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>What</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>will</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>I / you / he / she / it / we / they</td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>do tomorrow?</td>
                </tr>
            </tbody>
        </table>


                            <hr></hr>
                        <p>going to do - plans (informal)</p>
                        <p>about to do - will happen very soon and no time phrase can be added </p>
                        <p>thinking of doing</p>
                        <p>plan to do</p>
                        <p>planning to do</p>
                        <p>plan on doing</p>
                        <p>planning on doing</p>


                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default FutureExplanation;