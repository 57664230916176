import React from 'react';


const WordformsExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                        <p>Word forms action active actively to act</p>
                        <table className="grammartable" border="1">
  <tr>
    <th>Noun</th>
    <th>Verb</th>
    <th>Adjective</th>
    <th>Adverb</th>
  </tr>
  <tr>
    <td>decision</td>
    <td>decide</td>
    <td>decisive</td>
    <td>decisively</td>
  </tr>
  <tr>
    <td>efficiency</td>
    <td>to be efficient</td>
    <td>efficient</td>
    <td>efficiently</td>
  </tr>
  <tr>
    <td>success</td>
    <td>succeed</td>
    <td>successful</td>
    <td>successfully</td>
  </tr>
  <tr>
    <td>clarity</td>
    <td>clarify</td>
    <td>clear</td>
    <td>clearly</td>
  </tr>
  <tr>
    <td>exception</td>
    <td>none</td>
    <td>exceptional</td>
    <td>exceptionally</td>
  </tr>
  <tr>
    <td>value</td>
    <td>value</td>
    <td>valuable</td>
    <td>valuably</td>
  </tr>
  <tr>
    <td>effect</td>
    <td>affect</td>
    <td>effective</td>
    <td>effectively</td>
  </tr>
  <tr>
    <td>analysis</td>
    <td>analyze</td>
    <td>analytical</td>
    <td>analytically</td>
  </tr>
  <tr>
    <td>difference</td>
    <td>differ</td>
    <td>different</td>
    <td>differently</td>
  </tr>
  <tr>
    <td>action</td>
    <td>act</td>
    <td>active</td>
    <td>actively</td>
  </tr>
  <tr>
    <td>change</td>
    <td>change</td>
    <td>changing</td>
    <td>none</td>
  </tr>
  <tr>
    <td>explanation</td>
    <td>explain</td>
    <td>explanatory</td>
    <td>none</td>
  </tr>
  <tr>
    <td>argument</td>
    <td>argue</td>
    <td>argumentative</td>
    <td>none</td>
  </tr>
</table>




                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default WordformsExplanation;