import React from 'react';


const TenseExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            <h2>Tense Review</h2><br></br><br></br>

<h1>Present Simple</h1>
<p>Used for routines, facts, and general truths.<br></br>
<strong>Example:</strong> I work at Magyar Telekom.</p>

<h1>Present Continuous</h1>
<p>Used for actions happening now or temporary situations.<br></br>
<strong>Example:</strong> I am leading a small team.</p>

<h1>Present Perfect</h1>
<p>Used for actions with a connection to the present or experiences.<br></br>
<strong>Example:</strong> I have worked here for five years.</p>

<h1>Past Simple</h1>
<p>Used for completed actions in the past.<br></br>
<strong>Example:</strong> I graduated in 2018.</p>

<h1>Past Perfect</h1>
<p>Used for actions that happened before another past event.<br></br>
<strong>Example:</strong> I had never worked in a large company before.</p>

<h1>Future Simple</h1>
<p>Used for predictions or future decisions.<br></br>
<strong>Example:</strong> I will attend a training next month.</p>

<h1>Future Perfect</h1>
<p>Used for actions completed before a specific time in the future.<br></br>
<strong>Example:</strong> By next year, I will have completed my certification.</p>

<h1>Conditionals</h1>
<p>Used for hypothetical situations.<br></br>
<strong>Example:</strong> If I had more free time, I would take a cybersecurity course.</p>



                                        



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default TenseExplanation;





















