import React from 'react';


const PerfectpastExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            
                            <ul>
  <li><strong>Perfect vs Past</strong></li>
  <li><strong>I lived in Sacramento for 18 years.</strong></li>
  <li><strong>I have lived in Budapest for 33 years.</strong></li>
</ul>


                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default PerfectpastExplanation;