import React, { useState, useEffect } from 'react';
import questions from './Data/reviewquestions.json';
import '../../CSS/leveltest.css';
import ReadingMenu from '../../Components/Menus/ReadingMenu';
import Sidebar from '../../Components/Sidebar';
import leveltest from '../../Images/levels-header-1280x470.png';
import SEO from '../../Components/SEO';
import Breadcrumb from '../../Components/Breadcrumb';

const shuffleArray = (array) => {
  return array
    .map((item) => ({ item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ item }) => item);
};

const EnglishLevelTest = () => {
  const [hasStarted, setHasStarted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [result, setResult] = useState(null);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]); // Store the user's answers

  useEffect(() => {
    if (hasStarted) {
      setShuffledOptions(shuffleArray(questions[currentQuestionIndex].options));
    }
  }, [currentQuestionIndex, hasStarted]);

  const startTest = () => {
    setHasStarted(true);
    setCurrentQuestionIndex(0);
    setScore(0);
    setResult(null);
    setUserAnswers([]); // Reset answers when the test starts
    setShuffledOptions(shuffleArray(questions[0].options));
  };

  const resetTest = () => {
    setHasStarted(false);
    setCurrentQuestionIndex(0);
    setScore(0);
    setResult(null);
    setUserAnswers([]); // Reset answers when the test is reset
  };

  const handleAnswer = (selectedOption) => {
    // Store the user's selected answer
    setUserAnswers((prevAnswers) => [
      ...prevAnswers,
      { question: questions[currentQuestionIndex].question, selectedAnswer: selectedOption },
    ]);

    if (selectedOption === questions[currentQuestionIndex].correctAnswer) {
      setScore((prevScore) => prevScore + 1);
    }

    const nextQuestionIndex = currentQuestionIndex + 1;
    if (nextQuestionIndex < questions.length) {
      setCurrentQuestionIndex(nextQuestionIndex);
    } else {
      determineLevel();
    }
  };

  const determineLevel = () => {
    if (score <= 1) {
      setResult('Elementary');
    } else if (score <= 2) {
      setResult('Pre-intermediate');
    } else if (score <= 3) {
      setResult('Intermediate');
    } else if (score <= 4) {
      setResult('Upper-intermediate');
    } else {
      setResult('Advanced');
    }
  };

  const showCorrectSentences = () => {
    return (
      <div className="correct-answers-container">
        {questions.map((question, index) => {
          // Replace the placeholder (___) with the correct answer
          const correctSentence = question.question.replace('___', question.correctAnswer);
          return (
            <div className="correct-answer-row" key={index}>
              <div>{`Question ${index + 1}: ${correctSentence}`}</div>
            </div>
          );
        })}
      </div>
    );
  };

  if (!hasStarted) {
    return (
      <div>
        <SEO title="Vocabulary Quizzes - English Exam Exercises" description="A collection of flashcards to strengthen your vocabulary" name="English Exam Exercises" type="article" />
        <Breadcrumb />

        <div className="leveltest-container">
          <div className="homebodytext">
            <img src={leveltest} alt="English Exam Exercises" />
            <div className="introtext">
              <h3 className="mt-2">Level Test</h3>
              <p>Here is a collection of exercises organized by topic to help you synthesize the language.</p>
            </div>
            <div className="leveltest-container">
              <h1 className="leveltest-header">English Level Test</h1>
              <button onClick={startTest} className="start-button">Start Test</button>
            </div>
          </div>

          <div>
            <ReadingMenu />
            <Sidebar />
          </div>
        </div>
      </div>
    );
  }

  if (result) {
    return (
      <div>
        <div className="leveltest-container">
          <div className="homebodytext">
            <img src={leveltest} alt="English Exam Exercises" />
            <div className="introtext">
              <h3 className="mt-2">Level Test</h3>
              <p>Here is a collection of exercises organized by topic to help you synthesize the language.</p>
            </div>
            <div className="leveltest-container div1">
              <h1 className="leveltest-header">English Level Test</h1>
              <div className="result">Your level is: {result}</div>
              {showCorrectSentences()} {/* Show the full correct sentences here */}
              <button onClick={resetTest} className="restart-button">Restart Test</button>
            </div>
          </div>

          <div>
            <ReadingMenu />
            <Sidebar />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="leveltest-container">
        <div className="homebodytext">
          <img src={leveltest} alt="English Exam Exercises" />
          <div className="introtext">
            <h3 className="mt-2">Level Test</h3>
            <p>Here is a level test to determine your language level. The test has {questions.length} questions.</p>
          </div>

          <div className="leveltest-container div1">
            <h1 className="leveltest-header">English Level Test</h1>
            <div className="question-counter">
              Question {currentQuestionIndex + 1} of {questions.length}
            </div>
            <div className="questionbox">
              <p className="question">
                {currentQuestionIndex + 1}. {questions[currentQuestionIndex].question}
              </p>
              <div className="options">
                {shuffledOptions.map((option) => (
                  <button key={option} onClick={() => handleAnswer(option)}>
                    {option}
                  </button>
                ))}
              </div>
            </div>
            <button onClick={resetTest} className="reset-button">Reset Test</button>
          </div>

          
        </div>

        <div className="div2">
          <ReadingMenu />
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default EnglishLevelTest;
