import React from 'react';


const PastcontExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            
                            <ul>
  <li><strong>was/were doing</strong></li>
</ul>



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default PastcontExplanation;