import React from 'react';


const SecondcondExplanation = () => {

    return (
        <div>

<div className="grammar-display-grid">
                            <div className="agendagrammar">
                            <p>
Adjectives ending in <strong>-ed</strong> and <strong>-ing</strong> describe feelings or qualities, but they are used differently:
</p>
<p><strong>-ed adjectives:</strong></p>
<ul>
<li>Describe how someone <strong>feels</strong>.</li>
<li>Examples:</li>
<ul>
  <li><strong>I am excited</strong> about the new project.</li>
  <li><strong>She felt bored</strong> during the lecture.</li>
</ul>
</ul>
<p><strong>-ing adjectives:</strong></p>
<ul>
<li>Describe the <strong>cause</strong> of the feeling or the quality of something.</li>
<li>Examples:</li>
<ul>
  <li><strong>The movie was exciting</strong>. (The movie caused excitement)</li>
  <li><strong>The lecture was boring</strong>. (The lecture caused boredom)</li>
</ul>
</ul>
<p><strong>Key Notes:</strong></p>
<ul>
<li>Use <strong>-ed</strong> adjectives for the person experiencing the feeling (e.g., "I’m <strong>interested</strong>").</li>
<li>Use <strong>-ing</strong> adjectives for the source of the feeling (e.g., "The topic is <strong>interesting</strong>").</li>
</ul>



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default SecondcondExplanation;








