import React from 'react';


const TobepastExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            
                            <ul>
  <li><strong>I was</strong></li>
  <li><strong>You were</strong></li>
  <li><strong>He/She/It was</strong></li>
  <li><strong>We/You/They were</strong></li>
</ul>

<ul>
  <li><strong>I am not</strong></li>
  <li><strong>You were not</strong> </li>
  <li><strong>He/She/It was not</strong></li>
  <li><strong>We/You/They were not</strong></li>
</ul>

<ul>
  <li><strong>Was I...?</strong></li>
  <li><strong>Were you...?</strong></li>
  <li><strong>Was he/she/it...?</strong></li>
  <li><strong>Were we/you/they...?</strong></li>
</ul>



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default TobepastExplanation;