import React from 'react';


const TobepresExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            
                            <ul>
  <li><strong>I am =</strong> I'm</li>
  <li><strong>You are =</strong> You're</li>
  <li><strong>He/She/It is =</strong> He's/She's/It's</li>
  <li><strong>We/You/They are =</strong> We're, They're</li>
</ul>

<ul>
  <li><strong>I am not =</strong> I'm not</li>
  <li><strong>You are not =</strong> You're not, You aren't</li>
  <li><strong>He/She/It is not =</strong> He's/She's/It's not or He/She/It isn't</li>
  <li><strong>We/You/They are not =</strong> We're/They're not or We/They aren't</li>
</ul>

<ul>
  <li><strong>Am I...?</strong></li>
  <li><strong>Are you...?</strong></li>
  <li><strong>Is he/she/it...?</strong></li>
  <li><strong>Are we/you/they...?</strong></li>
</ul>



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default TobepresExplanation;