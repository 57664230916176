import React from 'react';


const ModalsdeductionExplanation = () => {

    return (
        <div>

<div className="grammar-display-grid">
                            <div className="agendagrammar">
                            <h2>Modals of Deduction</h2>

<h3>Present Deduction</h3>
<p>We use modal verbs to express how certain we are about something in the present.</p>
<ul>
    <li><strong>Must</strong> – We are sure something is true.  
        <br></br>Example: "He <strong>must</strong> be at work. His car is in the parking lot."
    </li>
    <li><strong>Can't / Cannot</strong> – We are sure something is not true.  
        <br></br>Example: "She <strong>can't</strong> be the manager. She's too young."
    </li>
    <li><strong>Might / May / Could</strong> – We think something is possible but are not certain.  
        <br></br>Example: "They <strong>might</strong> be on holiday, but I'm not sure."
    </li>
</ul>

<h3>Past Deduction</h3>
<p>For deductions about the past, we use modal verbs with <strong>have + past participle</strong>.</p>
<ul>
    <li><strong>Must have</strong> – We are sure something happened.  
        <br></br>Example: "He <strong>must have</strong> forgotten about the meeting."
    </li>
    <li><strong>Can't have / Couldn't have</strong> – We are sure something did not happen.  
        <br></br>Example: "She <strong>can't have</strong> finished the report already."
    </li>
    <li><strong>Might have / May have / Could have</strong> – We think something was possible in the past.  
        <br></br>Example: "They <strong>might have</strong> missed the train."
    </li>
</ul>


                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default ModalsdeductionExplanation;