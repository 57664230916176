import React from 'react';


const SimplePresentExplanation = () => {

    return (
        <div>

<div>
                            <div  className="agendagrammar">
                        <p>I go</p>
                        <p>you go </p>
                        <p>he, she, it goes </p>
                        <p>we, they go</p>


                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default SimplePresentExplanation;