module.exports = [
    {
      "id": "to-be",
      "title": "To Be Present",
      "category": "grammar",
      "level": "A1",
      "time": "15 min",
      "description": "Exercises to practice the verb 'to be' in the present",
      "image": "/Images/argue1.jpg"
    },
    {
      "id": "to-be-past",
      "title": "To Be Past",
      "category": "grammar",
      "level": "A1",
      "time": "45 min",
      "description": "Exercises to practice the verb 'to be' in the past",
      "image": "/Images/argue1.jpg"
    },
    {
      "id": "simple-present",
      "title": "Simple Present",
      "category": "grammar",
      "level": "A1",
      "time": "30 min",
      "description": "Exercises to practice the simple present tense",
      "image": "/Images/argue1.jpg"
    },
    {
      "id": "present-continuous",
      "title": "Present Continuous",
      "category": "grammar",
      "level": "A1",
      "time": "60 min",
      "description": "Exercises to practice the present continuous tense",
      "image": "/Images/argue3.jpg"
    },
    {
      "id": "simpcont",
      "title": "Simple Present vs. Present Continuous",
      "category": "grammar",
      "level": "A2",
      "time": "60 min",
      "description": "Exercises to practice the difference between the present continuous and the simple present tense",
      "image": "/images/argue4.webp"
    },
    {
      "id": "past-continuous",
      "title": "Past Continuous",
      "category": "grammar",
      "level": "A2",
      "time": "60 min",
      "description": "Exercises to practice the past continuous tense",
      "image": "/images/watchingsports3.jpg"
    },
    {
      "id": "simple-past",
      "title": "Simple Past",
      "category": "grammar",
      "level": "A2",
      "time": "60 min",
      "description": "Explore the fast food industry and its impact on society.",
      "image": "/images/watchingsports4.jpg"
    },
    {
      "id": "future",
      "title": "Future",
      "category": "grammar",
      "level": "A2",
      "time": "60 min",
      "description": "Discuss coffee culture and the effects of caffeine.",
      "image": "/images/watchingsports5.png"
    },
    {
      "id": "present-perfect",
      "title": "Present Perfect",
      "category": "grammar",
      "level": "B1",
      "time": "60 min",
      "description": "Set and discuss New Year's resolutions while practicing future plans.",
      "image": "/images/newyearsresolution.jpg"
    },
    {
      "id": "perfect-past",
      "title": "Present Perfect vs Past Simple",
      "category": "grammar",
      "level": "B1",
      "time": "60 min",
      "description": "Learn how to talk about personal and professional habits.",
      "image": "/images/habits.jpg"
    },
    {
      "id": "first-conditional",
      "title": "First Conditional",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss chat platforms while learning how to use 'so' and 'enough'.",
      "image": "/images/chatplatforms.jpg"
    },
    {
      "id": "first-cond-prod",
      "title": "First Conditional (Prompt)",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Explore automation trends and practice cause-and-effect structures.",
      "image": "/images/automation.jpg"
    },
    {
      "id": "second-conditional",
      "title": "Second Conditional",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Compare quality standards in various industries.",
      "image": "/images/quality.jpg"
    },
    {
      "id": "second-cond-prod",
      "title": "Second Conditional (Prompt)",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss choices in business and express wishes and regrets.",
      "image": "/images/choice.jpg"
    },
    {
      "id": "third-conditional",
      "title": "Third Conditional",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Learn about pet ownership and practice 'so' and 'enough'.",
      "image": "/images/pets.jpg"
    },
    {
      "id": "third-cond-prod",
      "title": "Third Conditional (Prompt)",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Talk about walking and its benefits while practicing gerunds.",
      "image": "/images/walking.jpg"
    },
    {
      "id": "mixed-cond-prod",
      "title": "Mixed Conditionals (Prompt)",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Describe people using relative clauses.",
      "image": "/images/people.jpg"
    },
    {
      "id": "indirect-speech",
      "title": "Indirect Speech",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss neighborly issues while practicing the 2nd conditional.",
      "image": "/images/neighbors.jpg"
    },
    {
      "id": "passive",
      "title": "Passive",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Talk about food production and use the passive voice.",
      "image": "/images/food.jpg"
    },
    {
      "id": "passivebeing",
      "title": "Passive - being",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss tourism and its impact while practicing gerunds.",
      "image": "/images/tourism.jpg"
    },
    {
      "id": "modals-obligation",
      "title": "Modals of Obligation",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Learn how to give and receive advice using 'suggest'.",
      "image": "/images/advice.jpg"
    },
    {
      "id": "modals-deduction",
      "title": "Modals of Deducttion",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Explore ethical dilemmas and practice using infinitives.",
      "image": "/images/ethics.jpg"
    },
    {
      "id": "sense-verbs",
      "title": "Sense Verbs",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss workplace gossip while practicing reported speech.",
      "image": "/images/gossip.jpg"
    },
    {
      "id": "ed-ing",
      "title": "Ed or Ing",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Explore sustainability practices while learning how to use 'unless'.",
      "image": "/images/sustainability.jpg"
    },
    {
      "id": "reported-speech",
      "title": "Reported Speech",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Practice problem-solving strategies and modals of deduction.",
      "image": "/images/problemsolving.jpg"
    },
    {
      "id": "gerunds",
      "title": "Gerunds",
      "category": "grammar",
      "level": "B2",
      "time": "60 min",
      "description": "Discuss medical advancements and their ethical implications.",
      "image": "/images/medicine.jpg"
    },
    {
        "id": "infinitives",
        "title": "Infinitives",
        "category": "grammar",
        "level": "B2",
        "time": "45 min",
        "description": "Learn persuasive techniques while practicing different word forms.",
        "image": "/images/persuading.jpg"
      },
      {
        "id": "unless",
        "title": "Unless",
        "category": "grammar",
        "level": "B1",
        "time": "30 min",
        "description": "Discuss sports experiences while practicing the present perfect tense.",
        "image": "/images/sports.jpg"
      },
      {
        "id": "adjadv",
        "title": "Adjectives and Adverbs",
        "category": "grammar",
        "level": "B2",
        "time": "60 min",
        "description": "Explore extreme weather events while using mixed conditionals.",
        "image": "/images/weather.jpg"
      },
      {
        "id": "pasthabits",
        "title": "Past Habits",
        "category": "grammar",
        "level": "B2",
        "time": "60 min",
        "description": "Talk about past educational experiences and express wishes and regrets.",
        "image": "/images/education.jpg"
      },
      {
        "id": "wish",
        "title": "Wishes and Regrets",
        "category": "grammar",
        "level": "B1",
        "time": "45 min",
        "description": "A lesson to assess students' understanding of various tenses.",
        "image": "/images/introlesson.jpg"
      },
      {
        "id": "comparison",
        "title": "Comparison",
        "category": "grammar",
        "level": "B2",
        "time": "60 min",
        "description": "Discuss the air traffic industry and aviation terminology.",
        "image": "/images/airtraffic.jpg"
      },
      {
        "id": "soenough",
        "title": "So and Enough",
        "category": "grammar",
        "level": "B2",
        "time": "60 min",
        "description": "Discuss the air traffic industry and aviation terminology.",
        "image": "/images/airtraffic.jpg"
      },
      {
        "id": "relativeclauses",
        "title": "Relative Clauses",
        "category": "grammar",
        "level": "B2",
        "time": "60 min",
        "description": "Discuss the air traffic industry and aviation terminology.",
        "image": "/images/airtraffic.jpg"
      },
      {
        "id": "causeeffect",
        "title": "Cause and Effect",
        "category": "grammar",
        "level": "B2",
        "time": "60 min",
        "description": "Discuss the air traffic industry and aviation terminology.",
        "image": "/images/airtraffic.jpg"
      },
      {
        "id": "word-forms",
        "title": "Word Forms",
        "category": "vocabulary",
        "level": "B2",
        "time": "60 min",
        "description": "Discuss the air traffic industry and aviation terminology.",
        "image": "/images/airtraffic.jpg"
      }
  ]
  