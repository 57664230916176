import React from 'react';


const DependentprepositionsExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                        <p>Dependent Prepositions angered by</p>



                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default DependentprepositionsExplanation;