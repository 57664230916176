import React from 'react';


const PresentperfectExplanation = () => {

    return (
        <div>

<div>
                            <div className="agendagrammar">
                            
                            <h2>Present Perfect Tense</h2>

<p>The present perfect is used to describe actions that have a connection to the present.</p>

<h3>Structure:</h3>
<p><strong>Subject + have/has + past participle</strong></p>

<h3>Uses:</h3>
<ul>
  <li><strong>Experience:</strong> "I have visited Japan."</li>
  <li><strong>Change over time:</strong> "She has become more confident."</li>
  <li><strong>Unfinished actions (with 'since' or 'for'):</strong> "They have lived here for five years."</li>
  <li><strong>Recent actions (with 'just', 'already', 'yet'):</strong> "He has just finished his work."</li>
</ul>





                                     </div>
                                    
                                </div>

        
        
        
        </div>
);
};

export default PresentperfectExplanation;